import _ from "lodash";
import * as yup from "yup";
export const createSchema = (isCreatePatient, testValue, currentSP) => {
  const infoSchema = yup.object().shape({
    // PATIENT schema
    testGRTemplate: yup.string().required("Mẫu phiếu không được để trống!"),
    codeBN: isCreatePatient
      ? yup
          .string()
          .required("Số điện thoại bệnh nhân không được để trống!")
          .length(
            10,
            "Số điện thoại bệnh nhân phải là số điện thoại gồm 10 số!"
          )
          .matches(
            "0[1-9][0-9]{8}",
            "Số điện thoại bệnh nhân phải là số điện thoại!"
          )
      : yup
          .string()
          .required("Số điện thoại bệnh nhân không được để trống!")
          .nullable(true),
    typePregnancy: yup.lazy((str) => {
      switch (testValue.toString()) {
        case "0": //Nhóm sản
          return yup.string().required("Số lượng thai không được để trống!");
        default:
          return yup.string().nullable();
      }
    }),
    nameBN: yup
      .string()
      .required("Họ tên bệnh nhân không được để trống!")
      .nullable(true),
    dateOfBirthBN: yup.string().required("Ngày sinh không được để trống!"),
    dateTM: yup
      .string()
      .required("Thời gian thu mẫu không được để trống!")
      .nullable(true),
    genderBN: yup
      .string()
      .required("Giới tính không được để trống!")
      .nullable(true),
    emailBN: yup
      .string()
      // .required('Email không được để trống!')
      .email("Email không đúng định dạng!")
      .nullable(true),
    phoneBN: yup
      .string()
      .required("Số điện thoại không được để trống!")
      .length(10, "Số điện thoại bao gồm 10 số!")
      .matches("0[1-9][0-9]{8}", "Vui lòng nhập đúng định dạng số điện thoại!")
      .nullable(true),
    nameContact: yup
      .string()
      .required("Họ tên người liên hệ không được để trống!")
      .nullable(true),
    pathological1: yup
      .string()
      .required("Bệnh lý cấp tính không được để trống!")
      .nullable(),
    addressBN: yup
      .string()
      .required("Địa chỉ bệnh nhân không được để trống!")
      .nullable(true),
    phoneContact: yup
      .string()
      .required("Số điện thoại người liên hệ không được để trống!")
      .length(10, "Số điện thoại bao gồm 10 số!")
      .matches("0[1-9][0-9]{8}", "Vui lòng nhập đúng định dạng số điện thoại!")
      .nullable(true),
    content: yup.string().required("Nội dung xét nghiệm không được để trống!"),
    numOfEmbryos: yup
      .string()
      .test(
        "check-numOfEmbryos",
        "Cân nặng phải là số nguyên dương!",
        (numOfEmbryos) => !numOfEmbryos || numOfEmbryos >= 0
      )
      .nullable(true),
    //test group infomation
    height: yup.lazy((height) => {
      switch (testValue.toString()) {
        case "2": //Nhóm bệnh lý
          return yup.string().nullable();
        default:
          return yup
            .string()
            .required("Chiều cao không được để trống!")
            .nullable(true);
      }
    }),
    weight: yup.lazy((weight) => {
      switch (testValue.toString()) {
        case "2": //Nhóm bệnh lý
          return yup.string().nullable();
        default:
          return yup
            .string()
            .required("Cân nặng không được để trống!")
            .nullable(true);
      }
    }),
    anamnesisBA: yup
      .string()
      .required("Tiền sử không được để trống!")
      .nullable(true),
    // cellNucleus: yup
    //   .string()
    //   .required('Nhân tế bào không được để trống!')
    //   .nullable(true),
    //
    codeTest: yup
      .string()
      .required("Thông tin xét nghiệm không được để trống!")
      .nullable(true),
    specimens: yup
      .array()
      .min(1, "Mẫu xét nghiệm không được để trống!")
      .required("Mẫu xét nghiệm không được để trống!")
      .nullable(),
    medicationsUsing: yup
      .array()
      .min(1, "Thuốc đang dùng không được để trống!")
      .required("Thuốc đang dùng không được để trống!")
      .nullable(),
    nameTC: yup
      .string()
      .required("Phòng khám không được để trống!")
      .nullable(true),
    lastNameBS: yup.string().nullable(true),
    firstNameBS: yup.string().nullable(true),
    nameBS: yup.string().when(["firstNameBS", "lastNameBS"], {
      is: (firstNameBS, lastNameBS) => {
        return !firstNameBS && !lastNameBS;
      },
      then: yup.string().required("Bác sĩ không được để trống!"),
      otherwise: yup.string(),
    }),
    whereTM: yup
      .string()
      .required("Nơi thu mẫu không được để trống!")
      .nullable(true),
    weekPregnancy: yup.lazy((str) => {
      switch (!!testValue ? testValue.toString() : "0") {
        case "0": //Nhóm sản
          return yup
            .string()
            .test(
              "check-weekPregnancy",
              "Tuần thai phải là số nguyên dương!",
              (weekPregnancy) => {
                try {
                  const week = parseInt(weekPregnancy);
                  return week >= 0;
                } catch (error) {
                  return false;
                }
              }
            )
            .required("Tuần thai không được để trống!");
        default:
          return yup.string().nullable();
      }
    }),
    dayOfPregnancy: yup.lazy((str) => {
      switch (!!testValue ? testValue.toString() : "0") {
        case "0": //Nhóm sản
          return yup
            .string()
            .test(
              "check-dayOfPregnancy",
              "Ngày thai phải là số nguyên dương!",
              (dayOfPregnancy) => {
                try {
                  const day = parseInt(dayOfPregnancy);
                  return day >= 0;
                } catch (error) {
                  return false;
                }
              }
            )
            .required("Ngày thai không được để trống!");
        default:
          return yup.string().nullable();
      }
    }),

    height1: yup
      .string()
      .test(
        "check-height1",
        "Chiều cao phải là số nguyên dương!",
        (height1) => !height1 || height1 >= 0
      )
      .nullable(true),
    // weight1: yup
    //   .string()
    //   .test(
    //     "check-weight1",
    //     "Cân nặng phải là số nguyên dương!",
    //     (weight1) => !weight1 || weight1 >= 0
    //   )
    //   .nullable(true),
    nappySkin: yup
      .string()
      .test(
        "check-nappySkin",
        "Độ mờ phải là số nguyên dương!",
        (nappySkin) => !nappySkin || nappySkin >= 0
      )
      .nullable(true),
    numOfEmbryos1: yup
      .string()
      .test(
        "check-numOfEmbryos1",
        "Số phôi tạo ra phải là số nguyên dương!",
        (numOfEmbryos1) => !numOfEmbryos1 || numOfEmbryos1 >= 0
      )
      .nullable(true),
    specifyVoteStatus: yup
      .string()
      .test(
        "check-numOfEmbryos1",
        "Không cho phép hủy phiếu xét nghiệm đang có đơn hàng!",
        (specifyVoteStatus) => {
          return (
            parseInt(specifyVoteStatus) !== -1 ||
            (!!currentSP?.order?.count && currentSP?.order?.count === 0) ||
            !currentSP?.order?.count
          );
        }
      )
      .nullable(true),
  });
  return infoSchema;
};
