import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Modal, Row } from 'reactstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import _ from 'lodash';
import queryString from 'query-string';

import {
  isNumeric,
  notificationAlertRef,
  notify,
  relationOneToOne,
} from 'common';
import LabelCustom from 'views/pages/components/LabelCustom';
import {
  accountActions,
  areaActions,
  formActions,
  orderActions,
  organizationActions,
  promotionDetailActions,
  specifyVotesActions,
  templatePCDActions,
  uploadActions,
} from 'redux/actions';
import HelperText from 'views/pages/components/HelperText';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import CONSTANT from 'constant';
import InputCustom from 'views/pages/components/InputCustom';
import SelectCustom from 'views/pages/components/SelectCustom';
import moment from 'moment';
import SwitchCustom from 'views/pages/components/SwitchCustom';

const CFOrder = ({ handleGetOrders }) => {
  const dispatch = useDispatch();

  // reducers
  const {
    isSampleCollectorStaff,
    isBusinessStaff,
    currentAccountExtend,
    childrentByCurrentAccountExtend,
    isAdmin,
  } = useSelector((state) => state.accountReducer);
  const { areasByAccountExtend } = useSelector((state) => state.areaReducer);
  const { isModalAddFastVisible } = useSelector((state) => state.formReducer);
  const { isCreatingOrder } = useSelector((state) => state.orderReducer);
  const { templatePcds } = useSelector((state) => state.templatePCDReducer);

  // Khởi tạo dữ liêu
  const initInfo = {
    code: 'HTG',
    name: '',
    organization: null,
    codeStaff1: null,
    codeStaff2: isSampleCollectorStaff ? currentAccountExtend.id : null,
    codeStaff3: isSampleCollectorStaff ? currentAccountExtend.id : null,
    nameTC: '',
    whereTM: '',
    amountMoney: 0,
    image: null,
    imageUrl: null,
    specifyVote: null,
    testGR: null,
    templatePcd: null,
    //
    idBS: -1,
    firstNameBS: '',
    lastNameBS: '',
    imagePaymentUrl: null,
    imagePayment: null,
    promotionDetail: null,
    codeCTKM: null,
    sendResultForPatient: true,
  };

  const [organizationSearch, setOrganizationSearch] = useState('');
  const [codeStaff2Search, setCodeStaff2Search] = useState('');
  const [codeStaff3Search, setCodeStaff3Search] = useState('');
  const [specifyVoteSearch, setSpecifyVoteSearch] = useState('');
  const [templateSearch, setTemplateSearch] = useState('');
  const [accountSearch, setAccountSearch] = useState('');

  const [paymentMethodValue, setPaymentMethodValue] = useState({
    label: 'Tiền mặt',
    value: 0,
  });
  const [accountValue, setAccountValue] = useState(null);
  const [organizationValue, setOrganizationValue] = useState(null);
  const [codeStaff1Search, setCodeStaff1Search] = useState('');
  const [codeStaff2Value, setCodeStaff2Value] = useState(null);
  const [codeStaff3Value, setCodeStaff3Value] = useState(null);
  const [specifyVoteValue, setSpecifyVoteValue] = useState(null);
  const [testGRValue, setTestGRValue] = useState(null);
  const [templatePcdValue, setTemplatePcd] = useState(null);
  const [codeStaff1Value, setCodeStaff1Value] = useState(null);

  const [accountResults, setAccountResults] = useState([]);
  const [organizationResults, setOrganizationResults] = useState([]);
  const [codeStaff1Results, setCodeStaff1Results] = useState([]);
  const [codeStaff2Results, setCodeStaff2Results] = useState([]);
  const [codeStaff3Results, setCodeStaff3Results] = useState([]);
  const [specifyVoteResults, setSpecifyVoteResults] = useState([]);

  // validate dữ liêu
  const infoSchema = yup.object().shape({
    code: yup.string().required('Mã đơn hàng không được để trống!'),
    name: yup.string().required('Tên đơn hàng không được để trống!'),
    organization: yup
      .number()
      .required('P.khám/Bệnh viện không được để trống!')
      .nullable(),
    firstNameBS: yup
      .string()
      .required('Bác sĩ chỉ định không được để trống!')
      .nullable(),
    codeStaff1: yup
      .number()
      .min(0, 'Nhân viên phụ trách không được để trống')
      .required('Nhân viên phụ trách không được để trống!')
      .nullable(),
    codeStaff2: yup
      .number()
      .min(0, 'Nhân viên thu mẫu không được để trống')
      .required('Nhân viên thu mẫu không được để trống!')
      .nullable(),
    imageUrl: yup
      .string()
      .required('Ảnh phiếu xét nghiệm không được để trống!')
      .nullable(),
    amountMoney: yup.number().min(0, 'Số tiền phải lớn hơn hoặc bằng 0!'),
    testGR: yup
      .number()
      .min(0, 'Nhóm xét nghiệm không được để trống')
      .required('Nhóm xét nghiệm không được để trống!')
      .nullable(),
    templatePcd: yup
      .number()
      .min(0, 'Mẫu in phiếu xét nghiệm không được để trống')
      .required('Mẫu in phiếu xét nghiệm không được để trống!')
      .nullable(),
    //
    // imagePayment:yup.string().required('Ảnh chuyển khoản không được để trống!').nullable(),
  });

  // Xử lý submit
  const onSubmit = (values, { resetForm }) => {
    if (!isSampleCollectorStaff && !isBusinessStaff && !isAdmin) return;
    const {
      code,
      name,
      organization,
      codeStaff1,
      codeStaff2,
      codeStaff3,
      amountMoney,
      specifyVote,
      testGR,
      areaName,
      nameTC,
      whereTM,
      templatePcd,
      image,
      //
      idBS,
      lastNameBS,
      firstNameBS,
      imagePayment,
      promotionDetail,
      codeCTKM,
      sendResultForPatient,
    } = values;
    const body = {
      code,
      name,
      amountMoney,
      areaName,
      organization: relationOneToOne(organization, null),
      codeStaff1: relationOneToOne(codeStaff1, null),
      codeStaff2: relationOneToOne(codeStaff2, null),
      codeStaff3: relationOneToOne(codeStaff3, null),
      isUpdated: specifyVote === null ? false : true, // biến này để cho phép chọn bệnh nhân sau khi thêm nhanh
      specifyVote: relationOneToOne(specifyVote, null),
      testGR,
      templatePcd: relationOneToOne(templatePcd, null),
      nameTC,
      whereTM,
      image,
      //
      idBS,
      lastNameBS,
      firstNameBS,
      imagePayment,
      promotionDetail: relationOneToOne(promotionDetail, null),
      codeCTKM,
      sendResultForPatient,
    };
    if (paymentMethodValue.value === 0) {
      delete body.imagePayment;
    } else {
      delete body.amountMoney;
      delete body.codeStaff3;
    }
    handleUploadFiles(values, body, resetForm);
  };

  const handleUploadFiles = (values, body, resetForm) => {
    const formData = new FormData();
    formData.append('files', values.image.file, values.image.file.name);
    if (!_.isEmpty(values.imagePayment)) {
      formData.append(
        'files',
        values.imagePayment.file,
        values.imagePayment.file.name
      );
    }
    dispatch(
      uploadActions.uploadFiles(formData, {
        success: (data) => {
          handleCreateFast(
            body,
            data.map((item) => item.id),
            resetForm
          );
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Tải ảnh lên thất bại!. Lỗi: ${mess}!`
          );
        },
      })
    );
  };

  const handleCreateFast = (body, imageIds, resetForm) => {
    body.image = imageIds[0];
    if (imageIds.length > 1) {
      body.imagePayment = imageIds[1];
    }
    if (body.testGR === 999999999) {
      delete body.testGR;
      delete body.templatePcd;
    }

    dispatch(
      orderActions.createOrder(body, {
        success: (data) => {
          dispatch(
            orderActions.updateOrder(data?.id, {
              image: body.image,
            })
          );
          handleGetOrders();
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Thêm mới đơn hàng thành công.`
          );
          resetForm();

          handleClose();
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Thêm mới đơn hàng thất bại!. Lỗi: ${mess}!`
          );
        },
      })
    );
  };

  // get Nhân viên thu mẫu
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetCodeStaff1s();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [codeStaff1Search]);

  const handleGetCodeStaff1s = () => {
    if (isBusinessStaff) {
      setCodeStaff1Results([
        {
          label: `${currentAccountExtend?.fullname ?? ''}`,
          value: currentAccountExtend?.id,
          ...currentAccountExtend,
        },
      ]);

      return;
    }

    const query = {
      'filters[$and][1][accountType][$eq]': 1,
      'filters[$and][2][isActive][$eq]': true,
    };
    if (codeStaff1Search !== '') {
      query['_q'] = codeStaff1Search;
    }

    dispatch(
      accountActions.getAccountsExtend(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setCodeStaff1Results(results);
          },
          failed: () => {},
        },
        false
      )
    );
  };

  // get specify votes
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetSpecifyVotes();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [specifyVoteSearch, accountValue]);

  const handleGetSpecifyVotes = () => {
    if (!accountValue?.value) return;
    const query = {
      'filters[$and][1][order][code][$null]': true,
      'filters[$and][2][$or][0][idBS][$eq]': accountValue.value,
      'filters[$and][2][$or][1][createdBy][id][$eq]': accountValue?.value,
      'filters[$and][3][specifyVoteStatus][$ne]': -1,
    };
    if (specifyVoteSearch !== '') {
      query['filters[$and][4][codePCD][$containsi]'] = specifyVoteSearch;
    }

    dispatch(
      specifyVotesActions.getSpecifyVotes(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setSpecifyVoteResults(results);
          },
          failed: () => {},
        },
        false
      )
    );
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetAccountBS();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [accountSearch, childrentByCurrentAccountExtend, areasByAccountExtend]);

  useEffect(() => {
    if (!!accountValue) {
      getRelationOrganizations(accountValue.value);
    }
  }, [accountValue]);

  const handleGetAccountBS = () => {
    const query = {
      'filters[$and][1][accountType][$eq]': CONSTANT.ACCOUNT_TYPE[5].value,
      'filters[$and][2][isActive][$eq]': true,
    };
    if (accountSearch !== '') {
      query[`filters[$and][3][fullname][$containsi]`] = accountSearch;
    }

    if (isBusinessStaff) {
      query['filters[$and][4][$or][4][staffInCharge][id][$eq]'] =
        currentAccountExtend.id;
      if (!_.isEmpty(childrentByCurrentAccountExtend)) {
        childrentByCurrentAccountExtend.forEach((c, i) => {
          query[`filters[$and][4][$or][${5 + i}][staffInCharge][id][$eq]`] =
            c.id;
        });
      }
    }

    if (!isAdmin && isSampleCollectorStaff) {
      areasByAccountExtend.forEach((item, index) => {
        query[`filters[$and][4][$or][${4 + index}][areas][id][$eq]`] = item.id;
      });
      dispatch(
        accountActions.getAccountsExtend(
          queryString.stringify(query),
          {
            success: (data) => {
              const results = _.get(data, 'results', []);
              setAccountResults(results);
            },
            failed: () => {},
          },
          false
        )
      );
      return;
    }

    dispatch(
      accountActions.getAccountsExtend(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setAccountResults(results);
          },
          failed: () => {},
        },
        false
      )
    );
  };

  const getRelationOrganizations = (id) => {
    dispatch(
      organizationActions.getOrganizationsByAccountExtend(id, {
        success: (data) => {
          setOrganizationResults(data);
        },
        failed: () => {},
      })
    );
  };

  // Xử lý đóng form
  const handleClose = () => {
    setAccountValue(null);
    setCodeStaff1Value(null);
    setCodeStaff2Value(null);
    setCodeStaff3Value(null);
    setOrganizationValue(null);
    setTestGRValue(null);
    setTemplatePcd(null);
    setSpecifyVoteValue(null);
    dispatch(
      formActions.setValue({
        name: 'isModalAddFastVisible',
        value: false,
      })
    );
    dispatch(
      formActions.setValue({
        name: 'modalInfo',
        value: {},
      })
    );
  };

  const handleGetPromotionDetailsByOrganization = (id, setFieldValue) => {
    if (id === null) return;
    const query = {
      'filters[$and][1][organization][id][$eq]': id,
      'filters[$and][2][isOut][$eq]': false,
      'filters[$and][3][startDate][$lte]': moment().toISOString(),
      'filters[$and][4][endDate][$gte]': moment().toISOString(),
    };

    dispatch(
      promotionDetailActions.getPromotionDetails(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            // setPromotionDetailResults(results);

            const totalMoney = 1000000000; // temp
            const promotions = results.map((item) =>
              handlePromotions(item, totalMoney)
            );
            const promotion = _.maxBy(promotions, 'reduce'); // Lấy giá giảm nhiều nhất

            setFieldValue('promotionDetail', promotion?.id ?? null);
            setFieldValue('codeCTKM', promotion?.code ?? '');
          },
          failed: () => {},
        },
        false
      )
    );
  };

  // Xử lý các chi tiết khuyến mại được áp dụng
  function handlePromotions(item, totalMoney) {
    let reduce = 0;

    if (
      !item.promotion ||
      !item?.promotion?.valuePromotion ||
      totalMoney === 0 ||
      !item.status ||
      !item?.isOut
    ) {
      return { ...item, reduce };
    }

    // check hết hạn khuyên mại, chi tiết khuyến mại đã bị xóa hay chưa, trạng thái hoạt động
    const now = new Date().getTime();
    const startDate = !!item?.startDate
      ? new Date(item.startDate).getTime()
      : 0;
    const endDate = !!item?.endDate ? new Date(item.endDate).getTime() : 0;

    if (
      now < startDate ||
      now > endDate ||
      item?.promotion?.deleted ||
      !item?.promotion?.status
    ) {
      return { ...item, reduce };
    }

    // tính số tiền được giảm
    if (
      item.promotion.form === 0 &&
      Number(item.promotion.valuePromotion) <= 100
    ) {
      // Giảm theo %
      reduce = (totalMoney * Number(item.promotion.valuePromotion)) / 100;
    } else if (item.promotion.form === 1) {
      //Giảm trực tiếp tiền
      reduce = Number(item.promotion.valuePromotion);
    }
    return {
      ...item,
      reduce,
    };
  }

  // get orgs
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // handleGetOrganizations();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [organizationSearch]);

  // const handleGetOrganizations = () => {
  //   const query = {
  //     'filters[$and][1][organizationType][$eq]': 1,
  //     'filters[$and][2][status][$eq]': 0,
  //   };
  //   if (organizationSearch !== '') {
  //     query['filters[$and][3][code][$containsi]'] = organizationSearch;
  //   }
  //   dispatch(
  //     organizationActions.getOrganizations(
  //       queryString.stringify(query),
  //       {
  //         success: (data) => {
  //           setOrganizationResults(data ?? []);
  //         },
  //         failed: () => {},
  //       },
  //       false
  //     )
  //   );
  // };

  // get Nhân viên thu mẫu
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetCodeStaff2s();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [codeStaff2Search]);

  const handleGetCodeStaff2s = () => {
    const query = {
      'filters[$and][1][accountType][$eq]': 0,
      'filters[$and][2][isActive][$eq]': true,
    };
    if (codeStaff2Search !== '') {
      query['_q'] = codeStaff2Search;
    }

    dispatch(
      accountActions.getAccountsExtend(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setCodeStaff2Results(results);
          },
          failed: () => {},
        },
        false
      )
    );
  };

  // get Nhân viên thu tien
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetCodeStaff3s();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [codeStaff3Search]);

  const handleGetCodeStaff3s = () => {
    const query = {
      'filters[$and][1][accountType][$eq]': 0,
      'filters[$and][2][isActive][$eq]': true,
    };
    if (codeStaff3Search !== '') {
      query['_q'] = codeStaff3Search;
    }

    dispatch(
      accountActions.getAccountsExtend(
        queryString.stringify(query),
        {
          success: (data) => {
            const results = _.get(data, 'results', []);
            setCodeStaff3Results(results);
          },
          failed: () => {},
        },
        false
      )
    );
  };

  useEffect(() => {
    if (isModalAddFastVisible && isSampleCollectorStaff) {
      setCodeStaff2Value({
        label: `${currentAccountExtend?.fullname ?? ''}`,
        value: currentAccountExtend.id,
      });
      setCodeStaff3Value({
        label: `${currentAccountExtend?.fullname ?? ''}`,
        value: currentAccountExtend.id,
      });
    } else {
      setCodeStaff2Value(null);
      setCodeStaff3Value(null);
    }
  }, [isModalAddFastVisible]);
  const handleGetTemplatePcds = () => {
    const query = {};
    if (templateSearch !== '') {
      query['filters[$and][1][name][$containsi]'] = templateSearch;
    }
    if (!!testGRValue) {
      query['filters[$and][0][testGR][$eq]'] = testGRValue?.value;
    }
    dispatch(
      templatePCDActions.getTemplatePCDs(queryString.stringify(query), {
        success: () => {},
        failed: () => {},
      })
    );
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetTemplatePcds();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [templateSearch, testGRValue]);
  const templates = templatePcds?.results ?? [];

  return (
    <Modal
      size={'md'}
      isOpen={isModalAddFastVisible}
      className="modal-dialog-centered modal-secondary"
      toggle={() => {
        handleClose();
      }}
    >
      <div className="modal-header border-bottom bg-white pb-2">
        <h2>Tạo nhanh thông tin đơn hàng</h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            handleClose();
          }}
        >
          <span style={{ color: '#262626' }} aria-hidden={true}>
            ×
          </span>
        </button>
      </div>
      <div className="modal-body bg-white rounded">
        <Formik
          initialValues={initInfo}
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={infoSchema}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => {
            return (
              <Form onSubmit={handleSubmit} className="">
                <Row>
                  <Col xs={12}>
                    <InputCustom
                      name="name"
                      label="Tên đơn hàng"
                      type="text"
                      placeholder="Nhập"
                      value={values.name}
                      isRequired={true}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors?.name}
                      touched={touched?.name}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <SelectCustom
                      name="firstNameBS"
                      label="Bác sĩ chỉ định"
                      placeholder="Lựa chọn"
                      value={accountValue}
                      isRequired={true}
                      isClearable={false}
                      handleChange={(e) => {
                        setSpecifyVoteValue(null);
                        setOrganizationValue(null);
                        setAccountValue(e);
                        setFieldValue('firstNameBS', e?.firstname ?? '');
                        setFieldValue('lastNameBS', e?.lastname ?? '');
                        setFieldValue('idBS', e?.value ?? -1);
                        if (!!e && e?.staffInCharge?.isActive) {
                          setFieldValue(
                            'codeStaff1',
                            e?.staffInCharge?.id ?? null
                          );
                          setCodeStaff1Value({
                            label: `${e?.staffInCharge?.fullname ?? ''}(${
                              e?.staffInCharge?.code ?? ''
                            })`,
                            value: e?.staffInCharge?.id,
                          });
                        } else {
                          setFieldValue('codeStaff1', null);
                          setCodeStaff1Value(null);
                        }
                      }}
                      handleBlur={handleBlur}
                      onInputChange={(value) => {
                        setAccountSearch(value);
                      }}
                      error={errors?.firstNameBS}
                      touched={touched?.firstNameBS}
                      options={accountResults.map((item) => ({
                        value: item.id,
                        label: `${item?.fullname ?? ''} (${item.code})`,
                        ...item,
                      }))}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <SelectCustom
                      name="organization"
                      label="Phòng khám/Bệnh viện"
                      placeholder="Lựa chọn"
                      value={organizationValue}
                      isDisabled={!accountValue}
                      isRequired={true}
                      isClearable={false}
                      handleChange={(e) => {
                        setFieldValue('organization', e?.value ?? null);
                        setFieldValue('areaName', e?.areaName ?? '');
                        setFieldValue('nameTC', e?.label ?? '');
                        setFieldValue('whereTM', e?.address ?? '');
                        setOrganizationValue(e);

                        handleGetPromotionDetailsByOrganization(
                          e?.value ?? null,
                          setFieldValue
                        );
                      }}
                      handleBlur={handleBlur}
                      onInputChange={(value) => {
                        setOrganizationSearch(value);
                      }}
                      error={errors.organization}
                      touched={touched.organization}
                      options={organizationResults.map((item) => ({
                        value: item?.id,
                        label: `(${item?.code ?? ''}) ${item?.name ?? ''}`,
                        areaName: `${item?.area?.name}`,
                        ...item,
                      }))}
                    />
                  </Col>
                  <Col xs={6}>
                    <SelectCustom
                      name="codeStaff1"
                      label="Nhân viên phụ trách"
                      placeholder="Lựa chọn"
                      value={codeStaff1Value}
                      isRequired={true}
                      isClearable={true}
                      handleChange={(e) => {
                        setCodeStaff1Value(e);
                        setFieldValue('codeStaff1', e?.value ?? null);
                      }}
                      handleBlur={handleBlur}
                      onInputChange={(value) => {
                        setCodeStaff1Search(value);
                      }}
                      error={errors.codeStaff1}
                      touched={touched.codeStaff1}
                      options={codeStaff1Results.map((item) => ({
                        label: `${item?.fullname ?? ''} (${item.code})`,
                        value: item?.id,
                      }))}
                    />
                  </Col>
                </Row>
                {organizationValue && (
                  <Row>
                    <Col xs={12}>
                      <LabelCustom
                        label={`Phương thức thanh toán: ${
                          CONSTANT.ORGANIZATION_METHOD_TYPE.find(
                            (o) => o.value === organizationValue.method
                          )?.label
                        }`}
                        style={{
                          whiteSpace: 'nowrap',
                          margin: '12px 8px 0px 0px',

                          lineHeight: '16.5px',
                        }}
                        styleLabel={{}}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xs={12} className="d-flex align-items-center">
                    <LabelCustom
                      label={`Gửi kết quả cho bệnh nhân: `}
                      style={{
                        whiteSpace: 'nowrap',
                        margin: '0px 8px 0px 0px',

                        lineHeight: '16.5px',
                      }}
                      styleLabel={{}}
                    />
                    <SwitchCustom
                      className="custom-toggle custom-toggle-primary mr-1"
                      value={values.sendResultForPatient}
                      labelOn="Bật"
                      labelOff={'Tắt'}
                      checked={values.sendResultForPatient}
                      onChange={(e) => {
                        setFieldValue('sendResultForPatient', e.target.checked);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <SelectCustom
                      name="paymentMethod"
                      label="Hình thức"
                      placeholder="Lựa chọn"
                      value={paymentMethodValue}
                      isRequired={false}
                      isClearable={false}
                      handleChange={(e) => {
                        setPaymentMethodValue(e);
                      }}
                      handleBlur={handleBlur}
                      error={errors.paymentMethod}
                      touched={touched.paymentMethod}
                      options={[
                        {
                          label: 'Tiền mặt',
                          value: 0,
                        },
                        {
                          label: 'Chuyển khoản',
                          value: 1,
                        },
                      ]}
                    />
                  </Col>
                </Row>

                {paymentMethodValue.value === 0 ? (
                  <Row>
                    <Col xs={6}>
                      <InputCustom
                        name="amountMoney"
                        label="Số tiền đã thu"
                        type="number"
                        placeholder="Nhập"
                        value={values.amountMoney}
                        isRequired={false}
                        handleChange={(e) => {
                          if (isNumeric(e.target.value)) {
                            handleChange(e);
                          }
                        }}
                        handleBlur={handleBlur}
                        error={errors?.amountMoney}
                        touched={touched?.amountMoney}
                      />
                    </Col>
                    <Col xs={6}>
                      <SelectCustom
                        name="codeStaff3"
                        label="Người thu tiền"
                        placeholder="Lựa chọn"
                        value={codeStaff3Value}
                        isRequired={false}
                        isClearable={false}
                        handleChange={(e) => {
                          setFieldValue('codeStaff3', e?.value ?? null);
                          setCodeStaff3Value(e);
                        }}
                        handleBlur={handleBlur}
                        onInputChange={(value) => {
                          setCodeStaff3Search(value);
                        }}
                        error={errors.codeStaff3}
                        touched={touched.codeStaff3}
                        options={codeStaff3Results.map((item) => ({
                          label: `${item?.fullname ?? ''} (${item.code})`,
                          value: item?.id,
                        }))}
                      />
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col className="d-flex flex-column" xs={12}>
                      <LabelCustom
                        // isRequired={true}
                        label={'Ảnh chuyển khoản'}
                        styleLabel={{ maxWidth: 120 }}
                      />
                      <input
                        className="mt-1"
                        style={{ fontSize: 12 }}
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          try {
                            if (_.isEmpty(e)) return;
                            setFieldValue('imagePayment', {
                              fileUrl: URL.createObjectURL(e.target.files[0]),
                              file: e.target.files[0],
                            });
                            setFieldValue(
                              'imagePaymentUrl',
                              URL.createObjectURL(e.target.files[0])
                            );
                          } catch (error) {
                            //
                          }
                        }}
                      />
                      {errors.imagePayment && touched.imagePayment && (
                        <HelperText message={errors.imagePayment} />
                      )}
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xs={6}>
                    <SelectCustom
                      name="codeStaff2"
                      label="Nhân viên thu mẫu"
                      placeholder="Lựa chọn"
                      value={codeStaff2Value}
                      isRequired={true}
                      isClearable={false}
                      handleChange={(e) => {
                        setFieldValue('codeStaff2', e?.value ?? null);
                        setCodeStaff2Value(e);
                      }}
                      handleBlur={handleBlur}
                      onInputChange={(value) => {
                        setCodeStaff2Search(value);
                      }}
                      error={errors.codeStaff2}
                      touched={touched.codeStaff2}
                      options={codeStaff2Results.map((item) => ({
                        label: `${item?.fullname ?? ''} (${item.code})`,
                        value: item?.id,
                      }))}
                    />
                  </Col>
                  <Col xs={6}>
                    <SelectCustom
                      name="specifyVote"
                      label="Mã phiếu xét nghiệm"
                      placeholder="Lựa chọn"
                      value={specifyVoteValue}
                      isRequired={false}
                      isClearable={true}
                      isDisabled={!accountValue}
                      handleChange={(e) => {
                        setSpecifyVoteValue(e);
                        setFieldValue('specifyVote', e?.value ?? null);
                        if (!e?.value) {
                          setFieldValue('testGR', null);
                          setFieldValue('templatePcd', null);
                          setTemplatePcd(null);
                          setTestGRValue(null);
                        } else {
                          setFieldValue('testGR', 999999999);
                          setFieldValue('templatePcd', 999999999);
                        }
                      }}
                      onInputChange={(value) => {
                        setSpecifyVoteSearch(value);
                      }}
                      handleBlur={handleBlur}
                      error={errors?.specifyVote}
                      touched={touched?.specifyVote}
                      options={specifyVoteResults.map((item) => ({
                        value: item?.id,
                        label: item?.codePCD ?? '',
                        ...item,
                      }))}
                    />
                  </Col>
                </Row>
                {!values.specifyVote && (
                  <Row>
                    <Col xs={6}>
                      <SelectCustom
                        name="testGR"
                        label="Nhóm xét nghiệm"
                        placeholder="Lựa chọn"
                        value={testGRValue}
                        isRequired={true}
                        isClearable={true}
                        handleChange={(e) => {
                          setTestGRValue(e);
                          setFieldValue('testGR', e?.value ?? null);
                        }}
                        handleBlur={handleBlur}
                        error={errors?.testGR}
                        touched={touched?.testGR}
                        options={CONSTANT.SPECIFY_VOTE_NXN}
                      />
                    </Col>
                    <Col xs={6}>
                      <SelectCustom
                        name="templatePcd"
                        label="Mẫu in phiếu xét nghiệm"
                        placeholder="Lựa chọn"
                        value={templatePcdValue}
                        isRequired={true}
                        isClearable={true}
                        handleChange={(e) => {
                          setTemplatePcd(e);
                          setFieldValue('templatePcd', e?.value ?? null);
                        }}
                        handleBlur={handleBlur}
                        onInputChange={(value) => {
                          setTemplateSearch(value);
                        }}
                        error={errors?.templatePcd}
                        touched={touched?.templatePcd}
                        isDisabled={!testGRValue}
                        options={
                          templates.map((item) => ({
                            label: `(${item?.code ?? ''}) ${item?.name ?? ''}`,
                            value: item.id,
                          })) ?? []
                        }
                      />
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col className="d-flex flex-column" xs={12}>
                    <LabelCustom
                      isRequired={true}
                      label={'Ảnh phiếu xét nghiệm và mẫu'}
                      styleLabel={{ maxWidth: 120 }}
                    />
                    <input
                      className="mt-1"
                      style={{ fontSize: 12 }}
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        try {
                          if (_.isEmpty(e)) return;

                          setFieldValue('image', {
                            fileUrl: URL.createObjectURL(e.target.files[0]),
                            file: e.target.files[0],
                          });
                          setFieldValue(
                            'imageUrl',
                            URL.createObjectURL(e.target.files[0])
                          );
                        } catch (error) {
                          //
                        }
                      }}
                    />
                    {errors.imageUrl && touched.imageUrl && (
                      <HelperText message={errors.imageUrl} />
                    )}
                  </Col>
                </Row>
                <div className="mb-0 pb-0 mt-4 d-flex justify-content-end">
                  <Button
                    className="text-nowrap mr-1"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Hủy
                  </Button>
                  <LoadingButtonCustom
                    color="info"
                    className="text-nowrap "
                    type="submit"
                    loading={isCreatingOrder}
                  >
                    Thêm mới
                  </LoadingButtonCustom>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default memo(CFOrder);
